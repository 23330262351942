import React from 'react';
import { FaSpinner } from "react-icons/fa";
import ActionMenu from './ActionMenu';
import LoadingButton from "../../../../components/LoadingButton";

const GroupCard = ({ index, deleteIndex, group, OnChangeAccountType, onAddAccount, handleClickMenu, handleEditGroup, handleRemoveGroup,handleRemoveGroupAcc }) => {

  return (
    <div className="bg-white shadow-md rounded-lg p-2 mb-6">

      <div className="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none pb-3">
        <div className="flex items-center gap-x-6">
          <img
            src="https://tailwindui.com/img/logos/48x48/tuple.svg"
            alt=""
            className="h-12 w-12 flex-none rounded-full ring-1 ring-gray-900/10"
          />
          <h1>
            <div className="mt-1 text-base font-semibold leading-6 text-gray-900">{group.grp_name}</div>
          </h1>
        </div>
        <div className="flex items-center gap-x-4 sm:gap-x-6">
          <a
            href="#"
            className="rounded-md bg-[#0172fd] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 "
            onClick={() => {
              handleEditGroup(group)
            }}
          >
            Edit
          </a>

          {
            deleteIndex === index ? (<LoadingButton title=" Removing" />) : (
              <a
                href="#"
                className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 "
                onClick={() => {
                  handleRemoveGroup(group, index);
                }}
              >
                Delete
              </a>
            )
          }


        </div>
      </div>

      {
        group?.m_group_acc_map.length > 0 ? (
          <div>
            <table className="min-w-full bg-white box-border border rounded-md">
              <thead>
                <tr>
                  <th className="py-1 px-2 font-light bg-gray-200">Account</th>
                  <th className="py-1 px-2 font-light bg-gray-200">Status</th>
                  <th className="py-1 px-2 font-light bg-gray-200">Action</th>
                </tr>
              </thead>
              <tbody>
                {group?.m_group_acc_map?.length > 0 && group?.m_group_acc_map?.map((account) => (
                  <tr key={account.gam_id}>
                    <td className="py-2 px-4 border-b text-sm font-light">
                      {account?.mt_user_account_relation?.mua_acc_login}
                      {
                        account && account?.gam_cp_symbol ? (
                          <span className='ml-1 rounded-sm py-1 px-1 text-xs font-light ring-1 ring-inset text-green-700 bg-green-50 ring-green-600/20'>
                            {account?.gam_cp_symbol}
                          </span>
                        ) : null
                      }
                      </td>
                    <td className="py-2 px-4 border-b">
                      <span className="isolate inline-flex rounded-md shadow-sm">
                        <button
                          type="button"
                          className={` ${account?.gam_acc_type == 2 ? ' bg-gray-700 text-white ' : 'bg-white text-gray-700'} " relative inline-flex items-center rounded-l-md  px-3 py-2 text-xs font-light  ring-1 ring-inset ring-gray-300 focus:z-10"`}
                          onClick={() => OnChangeAccountType(account?.fk_grp_id, account?.gam_acc_type, account, account?.mt_user_account_relation?.mua_acc_login, group?.m_group_acc_map, 2)}
                        >
                          Slave
                        </button>
                        <button
                          type="button"
                          className={` ${account?.gam_acc_type == 1 ? ' bg-gray-700 text-white ' : 'bg-white text-gray-700'} " relative -ml-px inline-flex items-center px-3 py-2 text-xs font-light ring-1 ring-inset ring-gray-300 focus:z-10"`}
                          onClick={() => OnChangeAccountType(account?.fk_grp_id, account?.gam_acc_type, account, account?.mt_user_account_relation?.mua_acc_login, group?.m_group_acc_map, 1)}
                        >
                          Master
                        </button>
                        <button
                          type="button"
                          className={` ${account?.gam_acc_type == 0 ? ' bg-gray-700 text-white ' : 'bg-white text-gray-700'} " relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-xs font-light ring-1 ring-inset ring-gray-300 focus:z-10"`}
                          onClick={() => OnChangeAccountType(account?.fk_grp_id, account?.gam_acc_type, account, account?.mt_user_account_relation?.mua_acc_login, group?.m_group_acc_map, 0)}
                        >
                          Off
                        </button>
                      </span>
                    </td>
                    <td className="py-2 px-4 border-b">
                      <ActionMenu handleClick={handleClickMenu} details={account} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button
              type="button"
              className="mt-2 inline-flex w-full justify-center rounded-md bg-[#0172fd] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => onAddAccount(group)}
            >
              + Add Account
            </button>
          </div>
        ) : (
          <div>
            <button
              onClick={() => {
                onAddAccount(group);
              }}
              type="button"
              className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400"
            >
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
                />
              </svg>
              <span className="mt-2 block text-sm font-semibold text-gray-900">
                Add Account
              </span>
            </button>
          </div>
        )
      }
    </div>
  );
};

export default GroupCard;
